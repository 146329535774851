import { addressDescription } from './stores/checkout'
const Vue = window.vue
const i18n = window.i18n

document.addEventListener('DOMContentLoaded', () => {
  const $el = document.getElementById('address-home')

  if ($el) {
    new Vue({
      i18n,
      el: $el,
      data: {
        addressDescription: addressDescription
      },
      mounted () {
        const headerBar = document.getElementById('header-bar')
        headerBar.classList.remove('d-none')
        headerBar.classList.add('d-flex')
      }
    })
  }
})
