const getLocalStorageValue = (key, defaultValue = null) => {
  let value = defaultValue

  if (key && window.localStorage) {
    const itemValue = window.localStorage.getItem(key)
    if (itemValue !== null) {
      value = itemValue
    }
  }

  return value
}

const setLocalStorageValue = (key, value) => {
  if (key && window.localStorage) {
    window.localStorage.setItem(key, value)
  }
}

const getSessionStorageValue = (key, defaultValue = null) => {
  let value = defaultValue

  if (key && window.sessionStorage) {
    const itemValue = window.sessionStorage.getItem(key)
    if (itemValue !== null) {
      value = itemValue
    }
  }

  return value
}

const setSessionStorageValue = (key, value) => {
  if (key && window.sessionStorage) {
    window.sessionStorage.setItem(key, value)
  }
}

export { getLocalStorageValue, setLocalStorageValue, getSessionStorageValue, setSessionStorageValue }
