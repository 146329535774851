import { getLocalStorageValue, setLocalStorageValue } from '../../utils/storage'
import { DateTime } from 'luxon'
const ct = DateTime.now().plus({ minutes: 45 })

const checkoutStore = {
  debug: false,
  state: {
    address: '',
    addressExtra: '',
    addressDescription: '',
    branchId: null,
    cash: 0,
    observation: '',
    customerName: '',
    customerMobile: '',
    deliveryDate: ct.toISODate(),
    deliveryTime: ct.toFormat('HH:mm'),
    deliveryTimeZone: ct.zoneName,
    deliveryType: 'delivery_now',
    localityId: null,
    paymentMethod: 'cash',
    sectionAndPlace: ''
  },
  getCheckoutSettings () {
    if (this.debug) console.log('getCheckoutSettings triggered')
    const currentSettings = getLocalStorageValue('checkout')
    if (currentSettings) {
      return Object.assign({}, this.state, JSON.parse(currentSettings))
    } else {
      return this.state
    }
  },
  loadCheckoutSettings () {
    if (this.debug) console.log('loadCheckoutSettings triggered')
    this.state = this.getCheckoutSettings()
  },
  saveCheckoutSettings () {
    if (this.debug) console.log('saveCheckoutSettings triggered', this.state)
    setLocalStorageValue('checkout', JSON.stringify(this.state))
  },
  setCheckoutSettings (newSettings = {}) {
    if (this.debug) console.log('setCheckoutSettings triggered', newSettings)
    if (typeof newSettings !== 'object') {
      return
    }

    this.state = Object.assign({}, this.state, newSettings)
    this.saveCheckoutSettings()
  }
}

checkoutStore.loadCheckoutSettings()
const addressDescription = checkoutStore.state.addressDescription

export { checkoutStore, addressDescription }
